import Vue from 'vue';
import App from './App.vue';
import Axios from 'axios';
import jQuery from 'jquery';
import Lodash from 'lodash';
import num_words from 'num-words';
import VueRouter from 'vue-router';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPenNib, faTrashAlt, faBars, faTimes, faBell, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faPenNib, faTrashAlt, faBars, faTimes, faBell, faStar);

Vue.component('font-awesome-icon', FontAwesomeIcon);

import { store } from '../store/store';

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// Import components
import Questions from './components/Questions.vue';
import CheckList from './components/CheckList.vue';
import Answers from './components/Answers.vue';
import Dashboard from './components/Dashboard.vue';
import Login from './components/Login.vue';
import Logout from './components/Logout.vue';
import gmQuestions from './components/GeneralManager/Questions.vue';
import gmChecklist from './components/GeneralManager/CheckList.vue';
import gmAnswers from './components/GeneralManager/Answers.vue';
import Meals from './components/Meals.vue';
import FeedbackQs from './components/Feedbacks/Questions.vue';
import FeedbackChk from './components/Feedbacks/CheckList';
import FeedbackAns from './components/Feedbacks/Answers';
import InventoryItems from './components/Inventory/Items'
import InventoryItemCategories from './components/Inventory/Categories.vue'
import InventoryOrders from './components/Inventory/Orders'
import InventoryDelivery from './components/Inventory/Delivery'
import InventoryDepartments from './components/Inventory/Departments'
import InventoryLocations from './components/Inventory/Locations'

Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.config.productionTip = false;

Vue.prototype.$axios = Axios;
Vue.prototype.$jquery = jQuery;
Vue.prototype.$_ = Lodash;
Vue.prototype.$numWords = num_words;
Vue.prototype.$URLaddress = 'https://respad.co.tz';
// Vue.prototype.$URLaddress = 'http://192.168.1.200/respad';

// global Mixin
Vue.mixin({
  methods: {
    capitalize: function(str) {
      return Array.from(str.toLowerCase())[0].toUpperCase() + str.slice(1);
    }
  }
})

const routes = [
  { path: '/', redirect: 'login' },
  { path: '/login', component: Login, name: 'Login' },
  { path: '/logout', component: Logout, name: 'Logout' },
  { path: '/dashboard', component: Dashboard, name: 'Dashboard' },
  { path: '/questions', component: Questions, name: 'Staff Questions' },
  { path: '/checklist', component: CheckList, name: 'Staff Checklists' },
  { path: '/answers', component: Answers, name: 'Staff Answers' },
  { path: '/gm/questions', component: gmQuestions, name: 'GM Questions' },
  { path: '/gm/checklist', component: gmChecklist, name: 'GM Checklist' },
  { path: '/gm/answers', component: gmAnswers, name: 'GM Answers' },
  { path: '/meals', component: Meals, name: 'Daily Meals' },
  { path: '/feedback/questions', component: FeedbackQs, name: 'Feedback Questions' },
  { path: '/feedback/checklist', component: FeedbackChk, name: 'Feedback Checklist' },
  { path: '/feedback/answers', component: FeedbackAns, name: 'Feedback Answers' },
  { path: '/inventory/items', component: InventoryItems, name: 'Inventory Items' },
  { path: '/inventory/item-categories', component: InventoryItemCategories, name: 'Inventory Item Categories' },
  { path: '/inventory/orders', component: InventoryOrders, name: 'Inventory Orders' },
  { path: '/inventory/deliveries', component: InventoryDelivery, name: 'Inventory Delivery' },
  { path: '/inventory/departments', component: InventoryDepartments, name: 'Inventory Departments' },
  { path: '/inventory/locations', component: InventoryLocations, name: 'Inventory Locations' }
]

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters.usertoken ? true : false;
  document.title = to.name !== undefined ? to.name : 'House Keeping App';

  // const isAllowedQuestions = store.getters.userPrivileges.questions === 'true' ? true : false

  if (to.path !== '/login' && !isAuthenticated) {
    return next('/login')
  } else if(to.path === '/login' && isAuthenticated) {
    return next('/dashboard')
  } else {
     next()
    }
})

new Vue({
  render: h => h(App),
  router, store,
}).$mount('#app')
