<template>
  <div class="nav thin-fonts">
    <div class="mobile-header">
      <button @click.prevent="toggleMenu" class="btn p-0 text-white-50 menu-button">
        <font-awesome-icon :icon="['fas', menuIcon]" class="fa-fw fa-2x" />
      </button>
    </div>
    <div class="menu-left">
      <router-link to="/dashboard">Dashboard</router-link>
      <router-link to="#" class="dropdown-parent">
        HouseKeeping
        <ul class="dropdown">
          <li><router-link to="/questions">Questions</router-link></li>
          <li><router-link to="/checklist">Checklist</router-link></li>
          <!-- <li><router-link to="/answers">Answers</router-link></li> -->
        </ul>
      </router-link>
      <router-link to="#" class="dropdown-parent">
        General Manager
        <ul class="dropdown">
          <li><router-link to="/gm/questions">Questions</router-link></li>
          <li><router-link to="/gm/checklist">Checklist</router-link></li>
          <li><router-link to="/gm/answers">Answers</router-link></li>
        </ul>
      </router-link>
      <router-link to="/meals">Daily Meals</router-link>
      <router-link to="#" class="dropdown-parent">
        Inventory
        <ul class="dropdown">
          <li><router-link to="/inventory/items">Items</router-link></li>
          <li><router-link to="/inventory/item-categories">Categories</router-link></li>
          <li><router-link to="/inventory/orders">Orders</router-link></li>
          <li><router-link to="/inventory/deliveries">Deliveries</router-link></li>
          <hr>
          <li><router-link to="/inventory/departments">Departments</router-link></li>
          <li><router-link to="/inventory/locations">Locations</router-link></li>
        </ul>
      </router-link>
      <router-link to="#" class="dropdown-parent">
        Feedbacks
        <ul class="dropdown">
          <li><router-link to="/feedback/questions">Questions</router-link></li>
          <li><router-link to="/feedback/checklist">Checklist</router-link></li>
          <li><router-link to="/feedback/answers">Answers</router-link></li>
        </ul>
      </router-link>
    </div>
    <div class="menu-right">
      <router-link to="#">User</router-link>
      <router-link @click.native.prevent="logout()" to="/logout">Logout</router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      menuIcon: 'bars'
    }
  },
  computed: {
    ...mapGetters([ 'usertoken', 'userPrivileges','navigation' ]),
    header: function() { 
      return this.$store.getters.navigation;
    }
  },
  watch: {
    header: function(h) {
      console.log('this header..:', h)
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout', { header: false });
      location.reload();
    },
    toggleMenu() {
      const nav = this.$jquery('.nav');
      if(!nav.hasClass('active')) {
        nav.addClass('active');
        this.menuIcon = 'times';
        this.$jquery('.menu-left > a, .menu-right > a').css('display','flex')
      } else {
        nav.removeClass('active');
        this.menuIcon = 'bars';
        this.$jquery('.menu-left > a, .menu-right > a').css('display','none')
      }
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Narrow&display=swap');

.thin-fonts {
  font-family: 'PT Sans Narrow', sans-serif;
}

@media only screen and (min-width: 768px) {
    .nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: gray;
    padding: 0 15px;
    margin-bottom: 30px;
    border-radius: 4px;
  }

  .nav .mobile-header {
      display: none;
    }
  .nav .mobile-header button {
    outline: none;
  }

  .menu-left > a, .menu-right > a {
    display: inline-block;
    line-height: 50px;
    margin: 0 5px;
    color: white;
    font-weight: bold;
    padding: 0 15px;
    transition: all .3s ease;
    position: relative;
  }

  .menu-left a:hover, .menu-right a:hover {
    color: rgba(255, 255, 255, .7);
    text-decoration: none;
  }

  .nav .dropdown-parent {
    /* border: 1px solid red; */
  }

  .nav .dropdown-parent:hover .dropdown {
    display: block;
  }

  .nav .dropdown {
    position: absolute;
    background: #eee;
    top: 50px;
    left: 0;
    min-width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 100;
    box-shadow: 0 1px 3px #333;
    border-radius: 0 0 4px 4px;
    display: none;
  }

  .nav .dropdown li a {
    position: relative;
    display: block;
    padding: 6px 30px 6px 15px;
    line-height: 30px;
    font-weight: normal;
    color: #333;
    transition: all .3s ease;
  }

  .nav .dropdown li a:hover {
    text-decoration: none;
    background: gray;
    color: #eee;
  }
}


/* Mobile version styles */
@media only screen and (max-width: 767px) {
    .nav {
      display: flex;
      flex-direction: column;
      background: gray;
      padding: 0 15px;
      margin-bottom: 30px;
      border-radius: 4px;
      position: relative;
    }

    .nav .mobile-header {
      display: block;
      position: absolute;
      top: 0; left: 0; width: 100%;
      padding: 10px 15px;
      text-align: right;
      color: #ccc;
      background: gray;
      border-radius: 4px;
    }

    .nav .menu-left {
      margin-top: 50px;
      border-top: 1px solid #999;
    }

    .menu-left > a, .menu-right > a {
      display: flex;
      flex-direction: column;
      line-height: 50px;
      margin: 0 15px;
      color: white;
      font-weight: bold;
      padding: 0 15px;
      transition: all .3s ease;
      position: relative;
      display: none;
    }

    .menu-left > a:hover, .menu-right > a:hover {
      border-radius: 4px 4px 0 0;
      text-decoration: none;
      background: #eee;
      color: #555;
    }

    .nav .dropdown-parent:hover .dropdown {
      display: block;
    }

    .nav .dropdown {
      position: absolute;
      background: #eee;
      top: 50px;
      left: 0;
      min-width: 100%;
      list-style: none;
      padding: 0;
      margin: 0;
      z-index: 100;
      box-shadow: 0 1px 3px #333;
      border-radius: 0 0 4px 4px;
      display: none;
    }

    .nav .dropdown li a {
      position: relative;
      display: block;
      padding: 6px 30px 6px 15px;
      line-height: 30px;
      font-weight: normal;
      color: #333;
      transition: all .3s ease;
    }

    .nav .dropdown li a:hover {
      text-decoration: none;
      background: gray;
      color: #eee;
    }
}
</style>