<template>
  <div id="app" class="container py-5">
    <Header v-if="showHeader" />
    <router-view></router-view>
  </div>
</template>

<script>
import Header from './components/Header';

export default {
  name: 'App',
  data() {
    return {
      showHeader: true
    }
  },
  components: {
    Header
  },
  mounted() {
    this.showHeader = this.$store.getters.usertoken ? true : false;
  },
  computed: {
    //
  }
}
</script>

<style>
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
