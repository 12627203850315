<template>
  <div class="row">
    <div class="col-12">
      <h3 class="mb-3">
        Categories
        <b-button pill v-b-modal.category class="float-right">Add Category</b-button>
      </h3>
      <table class="table">
        <thead>
          <tr>
            <th class="w-75">Name</th>
            <th>Edit</th>
            <th class="text-danger">Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(cat, i) in categories" :key="cat.id">
            <td>{{ cat.name }}</td>
            <td><a @click.prevent="loadCategory(i)" href="#">Edit</a></td>
            <td><a @click.prevent="deleteCategory(i)" class="text-danger" href="#">Delete</a></td>
          </tr>
        </tbody>
      </table>

      <b-modal id="category" scrollable centered>
        <template #modal-header>
          <h4>{{ edit ? 'Edit Category' : 'Add new Category' }}</h4>
          <b-button size="sm" variant="outline-danger" @click="$bvModal.hide('category'); clearCategoryForm()">Close</b-button>
        </template>
        <form ref="categoryForm" id="categoryForm">
          <label for="name">Name</label>
          <input type="text" v-model="category.name" name="name" id="name" class="form-control">
        </form>
        <template #modal-footer>
          <div class="w-100">
            <p class="float-left text-black-50 small pt-1">Click the button to add a new Category</p>
            <b-button variant="primary" size="sm" class="float-right" @click="validateData">
              {{ edit ? 'Save Changes' : 'Add Category' }}
            </b-button>
            <b-button variant="outline-secondary" size="sm" class="float-right mr-3" @click="clearCategoryForm">Clear</b-button>
          </div>  
        </template>
      </b-modal>
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      categories: [],
      category: { id: '', name: '' },
      edit: false,
      categoriesURL: ''
    }
  },
  computed: {
    ...mapGetters([ 'usertoken','cname', 'userProperties', 'userPlatform', 'platformURL' ]),
  },
  mounted() {
    this.categoriesURL = `${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/Preferences/categories`;
    this.getCategories();
  },
  methods: {
    validateData() {
      this.sendData()
    },
    async sendData() {
      if(this.edit) {
        // edit data
        let formData = new FormData(this.$refs.categoryForm)
        formData.append('_method', 'PUT')
        this.$axios.post(`${this.categoriesURL}/${this.usertoken}/${this.cname}/${this.category.id}`, formData)
          .then(res => {
            if(res.status === 200) {
              let theDep = this.categories.find(d => +d.id === +res.data.data.id);
              theDep.name = res.data.data.name; this.$bvModal.hide('category');
              this.clearCategoryForm();
            }
          })
          .catch(err => console.log(err))
        
      } else {
        // add data
        let formData = new FormData(this.$refs.categoryForm)
        formData.append('token', this.usertoken)
        formData.append('cname', this.cname)
        const { data: { data } } = await this.$axios.post(`${this.categoriesURL}`, formData)
        this.categories.push(data); this.$bvModal.hide('category'); this.clearCategoryForm();
      }
    },
    async getCategories() {
      const { data: { data } } = await this.$axios.get(`${this.categoriesURL}/${this.usertoken}/${this.cname}`)
      this.categories = data
    },
    loadCategory(i){
      this.edit = true; this.$bvModal.show('category')
      this.category.id = this.categories[i].id;
      this.category.name = this.categories[i].name;
    },
    deleteCategory(i) {
      if(confirm('Are you sure you want to delete this category?')) {
        this.$axios.delete(`${this.categoriesURL}/${this.usertoken}/${this.cname}/${this.categories[i].id}`)
          .then(res => {
            if(res.status === 200) this.categories.splice(i, 1)
          })
          .catch(err => console.log(err))
      }
    },
    clearCategoryForm() {
      this.edit = false; this.category.id = ''; this.category.name = '';
    }
  }
}
</script>