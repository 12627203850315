<template>
  <div class="row">
    <div class="col-12">
      <h3 class="mb-3">
        Deliveries
        <b-button pill v-b-modal.delivery class="float-right">Add Delivery</b-button>
      </h3>
      <table class="table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Property</th>
            <th>Location</th>
            <th class="text-center">Items</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(delivery, i) in deliveries" :key="delivery.id">
            <td>{{ delivery.date }}</td>
            <td>{{ delivery.property }}</td>
            <td>{{ delivery.location.name }}</td>
            <td class="text-center">
              <a href="#" 
                class="badge badge-secondary badge-pill" 
                @click.prevent="loadDeliveredItems(i)" 
                title="View delivered items"
              >{{ delivery.items.length }}</a>
            </td>
            <td><a href="#" title="Edit this Delivery" @click.prevent="loadDelivery(i)">Edit</a></td>
            <td><a href="#" :title="`Delete delivery dated ${delivery.date}`" @click.prevent="deleteDelivery(i)">Delete</a></td>
          </tr>
        </tbody>
      </table>

      <!-- Delivery Modal -->
      <b-modal id="delivery" centered scrollable size="lg">
        <template #modal-header>
          <h5>{{ edit ? `Edit Delivery` : 'Add a New Delivery' }}</h5>
          <b-button size="sm" variant="outline-danger" @click="$bvModal.hide('delivery'); clearForm()">Close</b-button>
        </template>
        
        <form ref="deliveryForm" id="deliveryForm">
          <div class="row">
            <div class="col-6 form-group">
              <label for="order">Select Order</label>
              <select name="order_id" v-model="delivery.orderID" id="order_id" class="form-control">
                <option value="" selected disabled>--- Select Order ---</option>
                <option v-for="order in orders" :value="order.id" :key="order.id">
                  {{ `Request to Order ${order.items.length} Items by ${order.property} on ${order.date}` }}
                </option>
              </select>
            </div>
            <div class="col-6 form-group">
              <label for="location_id">Select Delivery Location</label>
              <select name="location_id" v-model="delivery.locationID" id="location_id" class="form-control">
                <option value="" selected disabled>--- Select Location ---</option>
                <option v-for="location in locations" :value="location.id" :key="location.id">
                  {{ location.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-6 form-group">
              <label for="property">Select Property</label>
              <select name="property" v-model="delivery.property" id="property" class="form-control">
                <option value="" disabled selected>--- Select Property ---</option>
                <option v-for="property in allowedProperties" :value="property" :key="property">{{ property }}</option>
              </select>
            </div>
            <div class="col-6 form-group">
              <label for="item">Select Item</label>
              <select id="item" v-model="item.id" class="form-control" required>
                <option value="" disabled selected>--- Select Item ---</option>
                <option v-for="item in items" :value="item.id" :key="item.id">{{ item.name }}</option>
              </select>
            </div>
            <div class="col-3 form-group">
              <label for="qty">Quantity</label>
              <input type="number" v-model="item.qty" id="qty" min="1" class="form-control" required>
            </div>
            <div class="col-3">
              <p v-if="item.unit" style="margin-top: 35px">{{ item.unit }}</p>
            </div>
            <div class="col-6">
              <b-button 
                @click.prevent="addItemToDelivery" 
                variant="info" 
                size="sm" 
                style="margin-top: 30px" 
                class="float-right w-100"
              >Add Item</b-button>
            </div>
          </div>
        </form>

        <!-- Table of added items -->
        <div v-if="delivery.items.length" class="table-responsive mt-3">
          <h5>Added <span v-if="delivery.items.length">{{ delivery.items.length }}</span> Items for Delivery</h5>
          <table class="table">
            <thead>
              <tr>
                <th>Item</th>
                <th>Qty</th>
                <th><span class="float-right text-danger">Remove</span></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in delivery.items" :key="item.id">
                <td class="w-50">{{ item.name }}</td>
                <td class="w-25">{{ item.qty }}</td>
                <td>
                  <b-button 
                    @click.prevent="removeItemInDelivery(i)" 
                    variant="outline-danger" 
                    size="sm" 
                    class="float-right py-0"
                    >Remove
                  </b-button>
                  </td>
              </tr>
            </tbody>
          </table>
        </div>

        
        <template #modal-footer>
          <div class="w-100">
            <p class="float-left text-black-50 small pt-1">Click the button clear or add new Delivery</p>
            <b-button variant="primary" size="sm" class="float-right" @click="validateData">
              {{ edit ? 'Save Changes' : 'Add Delivery' }}
            </b-button>
            <b-button variant="outline-secondary" 
              size="sm" class="float-right mr-3" 
              @click.prevent="clearForm(); clearItem()"
            >Clear</b-button>
          </div>  
        </template> 
      </b-modal>

      <!-- Delivered Items Modal -->
      <b-modal hide-footer centered scrollable id="deliveredItems">
        <template #modal-header>
          <h5>
            View 
            <span v-if="delivery.items.length">{{ delivery.items.length }}</span> 
            Delivered Items 
            <span v-if="delivery.date">on {{ delivery.date }}</span> <br>
            <span class="small text-black-50">Delivery Location : {{ delivery.location.name }}</span>
          </h5>
          <b-button size="sm" variant="outline-danger" @click="$bvModal.hide('deliveredItems'); clearForm()">Close</b-button>
        </template>
        
        <table class="table">
          <thead>
            <tr>
              <th>Item Name</th>
              <th class="text-center">Quantity</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in delivery.items" :key="item.id">
              <td>{{ item.name }}</td>
              <td class="text-center">{{ item.qty }}</td>
            </tr>
          </tbody>
        </table>
        
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      deliveries: [], locations: [],
      item: { id: '', name: '', qty: '', unit: '' },
      delivery: { id: '', date: '', locationID: '', orderID: '', property: '', items: [] },
      orders: [], items: [],
      order: {
        id: '', date: '', property: '', cname: '', items: []
      },
      edit: false, allowedProperties: [],
      allowedPropertiesURL: '', ordersURL: '', 
      itemsURL: '', deliveriesURL: '', locationsURL:''
    }
  },
  mounted() {
    this.allowedPropertiesURL = `${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/Properties/allowed`;
    this.deliveriesURL = `${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/Inventory/deliveries`;
    this.locationsURL = `${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/Preferences/locations`;
    this.ordersURL = `${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/Inventory/orders`;
    this.itemsURL = `${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/Inventory/items`;
    this.getOrders(); this.getItems(); this.getAllowedProperties(); this.getDeliveries(); this.getLocations()
  },
  watch: {
    itemID: function(id) {
      if(id) {
        this.item.unit = this.items.find(i => i.id === id).unit
        this.item.name = this.items.find(i => i.id === id).name
      } else {
        this.item.unit = ''
        this.item.name = ''
      }
    },
    deliveryOrderID: function(id) {
      if(id) {
        const order = this.orders.find(o => o.id === id);
        this.delivery.property = order.property;
        this.delivery.items = order.items;
      } else {
        this.delivery.property = '';
        this.delivery.items = '';
      }
    }
  },
  computed: {
    ...mapGetters([
      'usertoken', 'userPlatform', 'platformURL', 'cname'
    ]),
    itemID: function() { return this.item.id },
    deliveryOrderID: function() { return this.delivery.orderID }
  },
  methods: {
    validateData() {
      this.sendDelivery();
    },
    sendDelivery() {
      if(this.edit) {
        // edit delivery
        let formData = new FormData(this.$refs.deliveryForm)
        formData.append('_method', "PUT")

        for(let i = 0; i < this.delivery.items.length; i++) {
          formData.append(`data[${i}][item_id]`, this.delivery.items[i].id)
          formData.append(`data[${i}][qty]`, this.delivery.items[i].qty)
        }

        this.$axios.post(`${this.deliveriesURL}/${this.usertoken}/${this.cname}/${this.delivery.id}`, formData)
          .then(res => { console.log(res)
            if(res.statusText === 'OK') {
              this.clearItem(); this.clearForm(); this.$bvModal.hide('delivery');
            }
          }
        ).catch(err => console.log(err))
      } else {
        // add delivery
        let formData = new FormData(this.$refs.deliveryForm)
        formData.append('token', this.usertoken)
        formData.append('cname', this.cname)

        for(let i = 0; i < this.delivery.items.length; i++) {
          formData.append(`data[${i}][item_id]`, this.delivery.items[i].id)
          formData.append(`data[${i}][qty]`, this.delivery.items[i].qty)
        }

        this.$axios.post(`${this.deliveriesURL}`, formData).then(res => {
          if(res.statusText === 'OK') {
            this.clearItem(); this.clearForm(); this.$bvModal.hide('delivery');
          }
        }).catch(err => console.log(err))
      }
    },
    addItemToDelivery() {
      const itemToAdd = this.delivery.items.find(i => i.id === this.item.id);
      if(itemToAdd === undefined) {
        this.delivery.items.push({ id: this.item.id, name: this.item.name, qty: this.item.qty });
        this.clearItem();
      } else {
        alert(`${itemToAdd.name} already exist`)
      }
    },
    removeItemInDelivery(i) {
      if(confirm('Are you sure you want to remove this item from Delivery ?')) {
        this.delivery.items.splice(i, 1);
      }
    },
    async getOrders() {
      const { data: { data } } = await this.$axios.get(`${this.ordersURL}/${this.usertoken}/${this.cname}`)
        this.orders = data;
    },
    async getItems() {
      const { data: { data } } = await this.$axios.get(`${this.itemsURL}/${this.usertoken}/${this.cname}`)
      this.items = data;
    },
    async getDeliveries() {
      const { data: { data } } = await this.$axios.get(`${this.deliveriesURL}/${this.usertoken}/${this.cname}`)
      this.deliveries = data;
    },
    async getLocations() {
      const { data: { data } } = await this.$axios.get(`${this.locationsURL}/${this.usertoken}/${this.cname}`)
      this.locations = data
    },
    async getAllowedProperties() {
      const { data : { data : { properties_allowed } } } = await this.$axios
        .get(`${this.allowedPropertiesURL}/${this.usertoken}/${this.cname}`);
      this.allowedProperties = properties_allowed
    },
    loadDelivery(i) {
      this.edit = true; this.$bvModal.show('delivery');
      let theDelivery = this.deliveries[i];

      this.delivery.id = theDelivery.id;
      this.delivery.date = theDelivery.date;
      this.delivery.cname = theDelivery.cname;
      this.delivery.property = theDelivery.property;
      this.delivery.items = theDelivery.items;
      this.delivery.locationID = theDelivery.location.ID;
      // this.delivery.orderID = theDelivery.order.id; // FIXME: We need order from Rama to load
    },
    loadDeliveredItems(i) {
      this.$bvModal.show('deliveredItems')
      this.delivery.id = this.deliveries[i].id;
      this.delivery.date = this.deliveries[i].date;
      this.delivery.cname = this.deliveries[i].cname;
      this.delivery.property = this.deliveries[i].property;
      this.delivery.location = this.deliveries[i].location;
      this.delivery.items = this.deliveries[i].items;
    },
    deleteDelivery(i) {
      if(confirm('Are you sure you want to delete this Delivery ?')) {
        this.$axios.delete(`${this.deliveriesURL}/${this.usertoken}/${this.cname}/${this.deliveries[i].id}`)
        .then(res => {
          if(res.statusText === 'OK') {
            this.deliveries.splice(i, 1);
          }
        })
        .catch(err => console.log(err))
      }
    },
    clearItem() {
      this.item.id = ''; this.item.name = ''; this.item.qty = ''; this.item.unit = '';
    },
    clearForm() {
      this.edit = false;
      this.delivery.id = ''; this.delivery.orderID = ''; this.delivery.locationID = ''; 
      this.delivery.date = ''; this.delivery.property = ''; 
      this.delivery.cname = ''; this.delivery.items = [];
    }
  }
}
</script>