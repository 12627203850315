<template>
  <div class="row">
    <div class="col-12">
      <h3 class="mb-3">
        Locations
        <b-button pill v-b-modal.location class="float-right">Add Location</b-button>
      </h3>
      <table class="table">
        <thead>
          <tr>
            <th class="w-75">Name</th>
            <th>Edit</th>
            <th class="text-danger">Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(loc, i) in locations" :key="loc.id">
            <td>{{ loc.name }}</td>
            <td><a @click.prevent="loadLocation(i)" href="#">Edit</a></td>
            <td><a @click.prevent="deleteLocation(i)" class="text-danger" href="#">Delete</a></td>
          </tr>
        </tbody>
      </table>

      <b-modal id="location" scrollable centered>
        <template #modal-header>
          <h4>{{ edit ? 'Edit Location' : 'Add new Location' }}</h4>
          <b-button size="sm" variant="outline-danger" @click="$bvModal.hide('location'); clearLocationForm()">Close</b-button>
        </template>
        <form ref="locationForm" id="locationForm">
          <label for="name">Name</label>
          <input type="text" v-model="location.name" name="name" id="name" class="form-control">
        </form>
        <template #modal-footer>
          <div class="w-100">
            <p class="float-left text-black-50 small pt-1">Click the button to add a newLocation</p>
            <b-button variant="primary" size="sm" class="float-right" @click="validateData">
              {{ edit ? 'Save Changes' : 'Add Location' }}
            </b-button>
            <b-button variant="outline-secondary" size="sm" class="float-right mr-3" @click="clearLocationForm">Clear</b-button>
          </div>  
        </template>
      </b-modal>
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      locations: [],
      location: { id: '', name: '' },
      edit: false,
      locationsURL: ''
    }
  },
  computed: {
    ...mapGetters([ 'usertoken','cname', 'userProperties', 'userPlatform', 'platformURL' ]),
  },
  mounted() {
    this.locationsURL = `${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/Preferences/locations`;
    this.getLocations();
  },
  methods: {
    validateData() {
      this.sendData()
    },
    async sendData() {
      if(this.edit) {
        // edit data
        let formData = new FormData(this.$refs.locationForm)
        formData.append('_method', 'PUT')
        this.$axios.post(`${this.locationsURL}/${this.usertoken}/${this.cname}/${this.location.id}`, formData)
          .then(res => {
            if(res.status === 200) {
              let theLoc = this.locations.find(d => +d.id === +res.data.data.id);
              theLoc.name = res.data.data.name; this.$bvModal.hide('location');
              this.clearLocationForm();
            }
          })
          .catch(err => console.log(err))
        
      } else {
        // add data
        let formData = new FormData(this.$refs.locationForm)
        formData.append('token', this.usertoken)
        formData.append('cname', this.cname)
        const { data: { data } } = await this.$axios.post(`${this.locationsURL}`, formData)
        this.locations.push(data); this.$bvModal.hide('location'); this.clearLocationForm();
      }
    },
    async getLocations() {
      const { data: { data } } = await this.$axios.get(`${this.locationsURL}/${this.usertoken}/${this.cname}`)
      this.locations = data
    },
    loadLocation(i){
      this.edit = true; this.$bvModal.show('location')
      this.location.id = this.locations[i].id;
      this.location.name = this.locations[i].name;
    },
    deleteLocation(i) {
      if(confirm('Are you sure you want to delete this location?')) {
        this.$axios.delete(`${this.locationsURL}/${this.usertoken}/${this.cname}/${this.locations[i].id}`)
          .then(res => {
            if(res.status === 200) this.locations.splice(i, 1)
          })
          .catch(err => console.log(err))
      }
    },
    clearLocationForm() {
      this.edit = false; this.location.id = ''; this.location.name = '';
    }
  }
}
</script>