import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    baseURL: 'https://respad.co.tz',
    // baseURL: 'http://192.168.1.200/respad',
    user: { 
      cname: '', // company name
      token: '',  // security token
      platform: '', // user platform / server
      properties: [], // user properties
      allowedProperties: [], // properties allowed to the user
      privilege: { 
        questions: false, // user allowed to mess with Questions? default no
        answers: false // user allowed to mess with answers? default no
      } 
    },
    gm: {
      answers: []
    },
    feedback: {
      answers: []
    },
    ui: { 
      navigation: false // UI - TODO: check if this feature is in use, remove if not..!
    },
  },
  getters: {
    cname: state => {
      if(localStorage.getItem('cname')) {
        state.user.cname = localStorage.getItem('cname');
        return state.user.cname;
      } else return '';
    },
    usertoken: state => {
      if(localStorage.getItem('token')) {
        state.user.token = localStorage.getItem('token');
        return state.user.token;
      } else return '';
    },
    navigation: state => {
      if(localStorage.getItem('token')) {
        state.ui.navigation = true;
        return state.ui.navigation;
      }
    },
    platformURL: state => {
      if(localStorage.getItem('platform')) {
        state.user.platform = localStorage.getItem('platform');
        return `${state.baseURL}/${state.user.platform}`;
      }
    },
    userPlatform: state => {
      if(localStorage.getItem('platform')) {
        state.user.platform = localStorage.getItem('platform');
        return state.user.platform;
      }
    },
    userProperties: state => {
      return state.user.properties;
    },
    userAllowedProperties: state => {
      return state.user.allowedProperties;
    },
    userPrivileges: state => {
      if(localStorage.getItem('Qprivs')) { state.user.privilege.questions = localStorage.getItem('Qprivs') }
      if(localStorage.getItem('Aprivs')) { state.user.privilege.answers = localStorage.getItem('Aprivs') }
      return state.user.privilege;
    },
    gmAnswers: state => {
      return state.gm.answers
    },
    feedbackAnswers: state => {
      return state.feedback.answers
    }
  },
  mutations: {
    login: (state, payload) => {
      // console.log(payload)
      state.ui.navigation = true;
      state.user.token = payload.token;
      state.user.cname = payload.cname;
      state.user.platform = payload.platform;
      state.user.properties = payload.properties;
      state.user.allowedProperties = payload.allowedProperties;
      state.user.privilege.answers = payload.AnswerPriveleges;
      state.user.privilege.questions = payload.QuestionPriveleges;
      localStorage.setItem('token', payload.token);
      localStorage.setItem('cname', payload.cname);
      localStorage.setItem('platform', payload.platform);
      // localStorage.setItem('Qprivs', payload.QuestionPrivileges);
      // localStorage.setItem('Aprivs', payload.AnswerPrivileges);
    },
    logout: (state, payload) => {
      state.ui.navigation = payload.header;
      state.user.token = '';
      state.user.cname = '';
      state.user.platform = '';
      state.user.properties = [];
      localStorage.removeItem('token');
      localStorage.removeItem('cname');
      localStorage.removeItem('platform');
      localStorage.removeItem('Qprivs');
      localStorage.removeItem('Aprivs');
    }
  },
  actions: {
    login: ({ commit }, payload) => {
      commit('login', payload);
    },
    logout: ({ commit }, payload) => {
      commit('logout', payload);
    }
  }
})