<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h3 class="mb-4">
          GM Tasks & Meetings - Questions / ToDos List
          <b-button pill v-b-modal.question class="float-right">Add Question/ToDo Task</b-button>
        </h3>
        <div v-if="client.questions.length" class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th style="max-width: 200px">Question</th>
                <th class="text-center">Sequence</th>
                <!-- <th class="text-center">Sessions</th> -->
                <th class="text-center">Properties</th>
                <th class="text-center">Task Types</th>
                <th class="text-center">Edit</th>
                <th class="text-center">Delete</th>
              </tr>
              <tr v-for="(question, i) in client.questions" :key="question.id">
                <td class="ellipsis" style="max-width: 200px" :title="question.question">{{ question.question }}</td>
                <td class="text-center">{{ question.sequence }}</td>
                <td class="text-center">
                  <span class="badge badge-pill badge-secondary">
                    {{ question.properties.length }}
                  </span>
                </td>
                <td class="text-center">
                  <span class="badge badge-pill badge-secondary">
                    {{ question.tasks.length }}
                  </span>
                </td>
                <td class="text-center">
                  <button @click.prevent="loadQuestion(i)" class="btn btn-sm text-primary" title="Edit">
                    <font-awesome-icon :icon="['fas', 'pen-nib']" />
                  </button>
                </td>
                <td class="text-center">
                  <button @click.prevent="confirmQuestionDelete(i)" class="btn btn-sm text-danger" title="Delete">
                    <font-awesome-icon :icon="['fas', 'trash-alt']" />
                  </button>
                </td>
              </tr>
            </thead>
          </table>
        </div>
        <h4 v-else class="text-danger"><hr>Sorry, No questions/todos added yet..!</h4>

        <!-- Add/Edit Question Modal -->
        <b-modal centered scrollable id="question">
          <template #modal-header>
            <h5>{{ edit ? 'Edit this Question' : 'Add a New Question' }}</h5>
            <b-button size="sm" variant="outline-danger" @click="$bvModal.hide('question'); clearForm()">Close</b-button>
          </template>
          <form id="gmQuestionForm" ref="gmQuestionForm">
            <!-- Hidden field -->
            <input type="hidden" name="cname" :value="question.cname">
            <!-- Visible fields -->
            <div class="form-group">
              <label for="property">Choose the Property</label>
              <div class="row">
                <div v-for="property in (!edit ? client.properties : question.properties)" class="col-lg-6" :key="property.id">
                  <label>
                    <input type="checkbox" v-model="property.checked"> {{ property.property }}
                  </label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-12 mb-3">Choose Question Task Type</div>
                <div v-for="type in (!edit ? taskTypes : question.taskTypes)" class="col-md-4" :key="type.id">
                  <label>
                    <input type="checkbox" v-model="type.checked"> {{ type.name }}
                  </label>
                </div>
              </div>            
            </div>
            <div class="form-group">
              <label for="answer_type">Expected Answer Type</label>
              <select name="answer_type" v-model="question.answerType" id="answer_type" class="form-control">
                <option value="" selected disabled>--- Select your expected answer type ---</option>
                <option value="Yes or No Answer">Yes or No Answer</option>
                <option value="Short text Answer">Short text Answer</option>
                <option value="Explanation Answer">Explanation Answer</option>
              </select>
            </div>
            <div class="form-group">
              <label for="question">Enter Your Question</label>
              <input type="text" 
                name="question" v-model="question.text" id="question" class="form-control" placeholder="Ex. Did you switch off the lights?">
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-8">
                  <label for="sequence">
                    Your Questions Sequence <br>
                    <small class="text-black-50">(Which starts & which follows)</small>
                  </label>
                </div>
                <div class="col-4 text-right">
                  <b-form-spinbutton v-if="edit" id="sb-inline" 
                    name="sequence" min="0" max="99" v-model="question.sequence" inline>
                  </b-form-spinbutton>
                  <b-form-spinbutton v-else id="sb-inline" 
                    name="sequence" min="0" max="99" :value="client.questions.length + 1" inline>
                  </b-form-spinbutton>
                </div>
              </div>
            </div>
          </form>
          <template #modal-footer>
            <div class="w-100">
              <p class="float-left text-black-50 small pt-1">Click the button to add a new Question</p>
              <b-button variant="primary" size="sm" class="float-right" @click="validateData">
                {{ edit ? 'Save Changes' : 'Add Question' }}
              </b-button>
              <b-button variant="outline-secondary" size="sm" class="float-right mr-3" @click="clearForm">Clear</b-button>
            </div>  
          </template> 
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      question: {
        id:'', 
        properties:[],
        taskTypes: [],
        text:'', 
        answerType: '', 
        sequence:''
      },
      edit: false, taskTypes: [],
      propertiesURL: `${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/Properties`,
      taskTypesURL: `${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/GeneralManager/tasktypes`,
      questionsURL: `${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/GeneralManager/questions`,
      client: { username: '', password: '', questions: [], properties: [] },
    }
  },
  mounted() {
    this.questionsURL = `${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/Settings`;
    this.getGMQuestions();
    this.getProperties();
    this.getTaskTypes();
  },
  computed: {
    ...mapGetters([ 'usertoken','cname', 'userProperties', 'userPlatform', 'platformURL' ]),
  },
  methods: {
    validateData() {
      this.addQuestion();
    },
    addQuestion() {
      if(this.edit) {
        // edit data
        let formData = new FormData(this.$refs.gmQuestionForm);
        this.question.properties.forEach(p => { 
          if(p.checked) formData.append('properties[]', p.property);
        });
        // this.question.roomTypes.forEach(r => {
        //   if(r.checked) formData.append('room_types[]', r.value);
        // });
        this.question.taskTypes.forEach(t => {
          if(t.checked) formData.append('tasks[]', t.id);
        });
        formData.append('_method', 'PUT');
        this.$axios
        .post(`${this.platformURL}/frontoffice/API/index.php/GeneralManager/questions/${this.usertoken}/${this.cname}/${this.question.id}`, formData)
          .then(res => { console.log(res.data.data)
            if(res.status === 200) {
              this.$bvModal.hide('question');
              const { data: { data: { properties, answer_type, room_types, question, sequence, session} } } = res;
              
              let Question = this.client.questions.find(q => q.id === this.question.id);
              
              Question.properties = properties; Question.answer_type = answer_type;
              Question.text = question; Question.room_types = room_types;
              Question.sequence = sequence; Question.session = session;

              this.showToast('Success', 'success', 'Question have been successfully updated');
              this.clearForm();
            }
          })
          .catch((err) => {
            this.showToast('Error', 'danger', err.message);
          })
      } else {
        // add data
        let formData = new FormData(this.$refs.gmQuestionForm);
        this.client.properties.forEach(p => { 
          if(p.checked) formData.append('properties[]', p.property);
        });
        this.taskTypes.forEach(t => {
          if(t.checked) formData.append('tasks[]', t.id);
        });
        formData.append('token', this.usertoken);
        formData.append('cname', this.cname);

        this.$axios.post(`${this.platformURL}/frontoffice/API/index.php/GeneralManager/questions/${this.usertoken}/${this.cname}`, formData)
          .then(res => { //console.log(res)
            if(res.status === 201) { //console.log(res)
              this.clearForm(); this.$bvModal.hide('question');
              const { data: { data: { id, properties, tasks, answer_type, cname, question, sequence } } } = res;
              this.client.questions.push({
                id: id, answer_type: answer_type, cname: cname, properties: properties,
                question: question, tasks: tasks, sequence: sequence
              });
              this.showToast('Success', 'success', 'Question have been successfully created');
            }
          })
          .catch(err => console.log(err))
      }
    },
    loadQuestion(i) {
      this.edit = true; this.$bvModal.show('question');
      this.question.id = this.client.questions[i].id;
      // this.question.sessions = this.client.questions[i].sessions;
      this.question.cname = this.client.questions[i].cname;
      this.question.answerType = this.client.questions[i].answer_type;
      this.question.text = this.client.questions[i].question;
      this.question.sequence = +this.client.questions[i].sequence;

      this.question.taskTypes = [...this.taskTypes]
      this.question.taskTypes.forEach(t => {
        t.checked = this.client.questions[i].tasks.find(el => +el === +t.id) !== undefined ? true : false
      })

      let thisQuestionProperties = [];
      this.client.questions[i].properties.forEach(p => {
        thisQuestionProperties.push({ property: p, checked: true });
      })
      this.question.properties = this.$_.unionBy(thisQuestionProperties, this.client.properties, 'property');
      
    },
    async getProperties() {
      const { data: { data } } = await this.$axios
        .get(`${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/Properties/${this.usertoken}/${this.cname}`)
        .catch(err => console.log(err));
        
      data.forEach(p => {
          this.client.properties.push({
            property: p,
            checked: false
          })
        }
      );
    },
    async getGMQuestions() {      
      const { data: { data } } = await this.$axios
        .get(`${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/GeneralManager/questions/${this.usertoken}/${this.cname}`)
      this.client.questions = data;
    },
    async getTaskTypes() {
      const { data: { data } } = await this.$axios
        .get(`${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/GeneralManager/tasktypes/${this.usertoken}/${this.cname}`)
      data.forEach(el => {
        this.taskTypes.push({
          id: el.ID, name: el.name, checked: false
        })
      })
    },
    confirmQuestionDelete(i) {
      
      let confirm = null;
      let id = this.client.questions[i].id;
      this.$bvModal.msgBoxConfirm('Are you sure you want to permanently delete this Question?', {
        title: 'Please Confirm',
        size: 'sm',
        // buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          let vm = this;
          confirm = value;
          // delete question if confirmed (yes)
          if(confirm) {
            vm.$axios.delete(`${vm.platformURL}/frontoffice/API/index.php/GeneralManager/questions/${this.usertoken}/${this.cname}/${id}`)
              .then(res => {
                if(res.status === 200) {
                  this.showToast('Success', 'success', 'Question have been successfully deleted');
                  vm.client.questions.splice(i,1);
                }
              })
              .catch(() => { 
                this.showToast('Sorry', 'danger', 'Something went wrong, we couldn\'t delete the Question. Please try again later');
              }
            );
          }
        })
        .catch(err => {
          this.showToast('Error', 'danger', err.message);
        }
      );
    },
    showToast(title = '', variant = '', body) {
      this.$bvToast.toast(body, {
        title: `${ title || 'Information' }`,
        variant: `${ variant || 'default' }`,
        toaster: 'b-toaster-top-center',
        solid: true
      })
    },
    clearForm() {
      this.question.id = ''; this.question.text = ''; this.question.properties = [];
      this.question.answerType = ''; this.question.sequence = ''; 
      this.question.taskTypes = [];
      document.querySelector('#gmQuestionForm').reset(); this.edit = false;
    }
  }
}
</script>