<template>
  <div>
    <div class="row">
      <div class="col-12">
        <form ref="photosForm" id="photosForm">
          <div class="row">
            <div class="col-lg-4 form-group">
              <label for="property">Select Property</label>
              <select name="property" v-model="chef.property" id="property" class="form-control">
                <option value="" selected disabled>--- Select Property ---</option>
                <option v-for="property in allowedProperties" :value="property" :key="property">{{ property }}</option>
              </select>
            </div>
            <div class="col-lg-4 form-group">
              <label for="date">Select Date</label>
              <DatePicker value-type="format" format="YYYY-MM-DD" v-model="chef.date" class="w-100" />
            </div>
            <div class="col-lg-4 form-group">
              <label for="meal">Select Meal</label>
              <select name="meal" v-model="chef.meal" id="meal" class="form-control">
                <option value="" selected disabled>--- Select Meal ---</option>
                <option value="wake up call">Wake up call</option>
                <option value="breakfast">Breakfast</option>
                <option value="breakfast box">Breakfast box</option>
                <option value="bush lunch">Bush Lunch</option>
                <option value="lunch box">Lunch Box</option>
                <option value="dinner">Dinner</option>
                <option value="bush dinner">Bush Dinner</option>
                <option value="special food">Special Food (vegetarian, vegan etc.)</option>
              </select>
            </div>
          </div>
          <div class="row d-flex align-items-end">
            <div class="col-lg-4 form-group">
              <label for="photos">Upload Photos ( <strong class="text-danger">max 5 ( {{ numWords(5) }} )</strong> )</label>
              <input type="file" ref="photosupload" name="photos[]" 
                @change="selectPhotos" id="photos" class="form-control" 
                accept="image/*" multiple
              >
            </div>
            <div class="col-lg-4 form-group">
              <button @click.prevent="clearPhotosInput" class="btn btn-lg btn-outline-danger btn-block">Clear Photos</button>
            </div>
            <div class="col-lg-4 form-group">
              <button 
                @click.prevent="validateData" 
                class="uploadButton btn btn-lg btn-primary btn-block"
                :disabled="!chef.property || !chef.date || !chef.meal || !chef.photoPreviews.length"
              >Send Photos</button>
            </div>
          </div>
          <div v-if="chef.photoPreviews.length" class="row">
            <div class="col-12">
              <h5 class="mt-3 text-success text-center text-lg-left">
                Photos to be uploaded, Accepted? Click Send Photos button above
              </h5> <hr>
              <div class="card-columns">
                <div v-for="p in chef.photoPreviews" class="card" :key="p">
                  <img :src="p" class="card-img-top" :alt="`p Preview`">
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <!-- show uploaded photos -->
    <div v-if="mealPhotoRecords.length" class="row mt-4">
      <div class="col-12">
        <h3 class="text-success">Uploaded Meal Photos
          <span class="float-right">
            <small class="text-black-50 mr-3">Filter by date(default - today)</small>
            <DatePicker value-type="format" format="YYYY-MM-DD" v-model="recordsFilterBy.date" class="" /> 
          </span>
        </h3><hr>
        <div class="row" style="font-weight: bold">
          <div class="col-3 col-md-3">Upload Date</div>
          <div class="col-3 col-md-3">Meal</div>
          <div class="col-2 col-md-2">Property</div>
          <div class="col-3 col-md-3 text-center">View | Hide Photos</div>
          <div class="col-1 col-md-1 text-danger">Remove</div>
        </div>
        <hr>
      </div>

      <div class="col-12 p-0" v-if="dateFilteredPhotosRecords.length">
        <div v-for="(record, i) in dateFilteredPhotosRecords" class="col-12 mb-2" :key="record.id">
          <div class="row pt-2">
            <div class="col-3 col-md-3">{{ format(new Date(record.date), "do MMM yyyy") }}</div>
            <div class="col-3 col-md-3">{{ capitalize(record.meal) }}</div>
            <div class="col-2 col-md-2">{{ record.property }}</div>
            <div class="col-3 col-md-3 text-center text-success">
              <button @click.prevent="record.showImages = !record.showImages" class="btn btn-outline-primary py-0">
                {{ record.showImages ? 'Hide' : 'View' }} {{ record.images.length }} {{ record.images.length > 1 ? 'photos' : 'photo' }}
              </button>
            </div>
            <div class="col-1 col-md-1 text-danger" 
              :title="`Remove ${numWords(record.images.length)} ${capitalize(record.meal)} photos of ${record.date}`">
              <button @click.prevent="removeUploadedPhotos(i, record.id)" class="btn btn-sm btn-link text-danger p-0">
                Remove
              </button>
            </div>
            <transition name="fade-slide">
              <div v-if="record.showImages" class="col-12 card-columns pt-3 mt-2 bg-secondary rounded">
                <div v-for="image in record.images" class="card" :key="image">
                  <img :src="`${uploadedPhotosPath}/${image}`" class="card-img-top">
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div v-else class="col-12 text-center">
        <h3 class="text-danger text-center">Sorry, no photos uploaded for {{ recordsFilterBy.date }}</h3>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { format } from 'date-fns'

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      chef: {
        property: '', date: '', meal: '', photos: [], photoPreviews: []
      },
      recordsFilterBy: { date: '' },
      format: format,
      numWords: this.$numWords,
      mealPhotoRecords: [],
      uploadedPhotosPath: `${this.$URLaddress}/blue/frontoffice/API/assets/uploads`,
      allowedPropertiesURL: '', 
      kitchenURL: '',
      allowedProperties: []
    }
  },
  mounted() {
    this.allowedPropertiesURL = `${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/Properties/allowed`;
    this.kitchenURL = `${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/Kitchen`;
    this.getAllowedProperties();
    this.getUploadedPhotos();
    this.recordsFilterBy.date = this.initialDate(new Date());
    this.chef.date = this.initialDate(new Date());
  },
  computed: {
    ...mapGetters([
      'userProperties', 'userAllowedProperties','usertoken', 
      'userPlatform', 'platformURL', 'cname'
    ]),
    dateFilteredPhotosRecords() {
      return this.mealPhotoRecords.filter(p => {

        return p.date === this.recordsFilterBy.date

      })
    }
  },
  methods: {
    validateData() {
      // this.requestQuestions()
      this.sendPhotos()
    },
    sendPhotos() {
      let uploadButton = document.querySelector('.uploadButton')
      uploadButton.textContent = 'Uploading, please wait...'

      let formData = new FormData(this.$refs.photosForm);
      formData.append('token', this.usertoken);
      formData.append('cname', this.cname);
      for(let p of this.chef.photos) {
        formData.append('files[]', p)
      }
      this.$axios.post(`${this.kitchenURL}`, formData)
        .then(res => { // console.log(res)
          if(res.status === 201) {
            uploadButton.textContent = 'Send Photos'
            this.showToast('Congratulations', 'success', 'Meal photos have been successfully uploaded');
            this.mealPhotoRecords.push({
              cname: res.data.data.cname,
              date: res.data.data.date,
              id: res.data.data.id,
              images: res.data.data.images,
              meal: res.data.data.meal,
              property: res.data.data.property,
              showImages: false
            })

            this.chef.property = ''; this.chef.meal = '';
            this.chef.photos = []; this.chef.photoPreviews = [];
          }
        })
        .catch(err => console.log(err));
    },
    initialDate(date) {
      let theDate = date.toLocaleDateString()
        .split('/')
        .slice(-1) + '-' 
        + date.toLocaleDateString()
        .split('/')
        .map(i => i.length > 1 ? i : '0'.concat(i))
        .slice(0, -1)
        .join('-');

      return theDate;
    },
    // getUploadedPhotos() {    
    //     this.$axios.get(`${this.kitchenURL}/${this.usertoken}/${this.cname}`)
    //       .then(res => {
    //         let records = res.data.data;
    //         for(let i of records) {
    //           i.showImages = false;
    //         }
    //         this.mealPhotoRecords = records;
    //       })
    //       .catch(err => console.log(err))
    // },
    async getUploadedPhotos() {    
        try {
          const { data: { data } } = await this.$axios.get(`${this.kitchenURL}/${this.usertoken}/${this.cname}`)
          let records = data;
            for(let i of records) {
              i.showImages = false;
            }
            this.mealPhotoRecords = records;
        } catch(e) {
          if(e.response) {
            console.log(e.response.data.message);
          } else if(e.request) {
            const err = new Error('Sorry, there is a Request Error. Try checking connection...');
            console.log(err.message);
          } else {
            console.log(e.message);
          }
        }
    },
    removeUploadedPhotos(i, id) {
      let vm  = this;
      if(confirm('Are you sure you want to remove this meal photos record ?')) {
        vm.$axios.delete(`${vm.kitchenURL}/${vm.usertoken}/${vm.cname}/${id}`)
          .then(res => { //console.log(res)
            if(res.status === 200) {
              vm.mealPhotoRecords.splice(i, 1);
              this.showToast(
                'Congratulations', 
                'success', 
                `${this.capitalize(res.data.data.meal)} meal photos of ${res.data.data.date} have been successfully removed`
              );
              location.reload();
            }
          })
          .catch(err => console.log(err))
      }
    },
    // getAllowedProperties() {
    //   this.$axios.get(`${this.allowedPropertiesURL}/${this.usertoken}/${this.cname}`)
    //     .then(res => {
    //       if(res.status === 200) this.allowedProperties = res.data.data.properties_allowed;
    //     })
    // },
    async getAllowedProperties() {
      try {
        const { data: { data } } = await this.$axios.get(`${this.allowedPropertiesURL}/${this.usertoken}/${this.cname}`)
        this.allowedProperties = data.properties_allowed;
      } catch(e) {
        if(e.response) {
            console.log(e.response.data.message)
          } else if(e.request) {
            const err = new Error('Sorry, there is a Request Error. Try checking connection...');
            console.log(err.message);
          } else {
            console.log(e.message)
          }
        }
    },
    selectPhotos(e) {
      if(e.target.files.length > 5) {
        this.showToast(
          `Sorry, ${this.numWords(e.target.files.length)} is too many photos..!`,
          'danger', 
          `Max number of allowed photos is 5 ( ${this.numWords(5)} )`
        );
        this.$refs.photosupload.value = null;
      } else {
        // clear existing photos
        this.chef.photos = [];
        this.chef.photoPreviews = [];
        // loop through to preview photos
        for(let file of e.target.files) {
          this.chef.photos.push(file);
          this.chef.photoPreviews.push(URL.createObjectURL(file));
        }
      }
    },
    clearPhotosInput() {
      this.$refs.photosupload.value = null;
      this.chef.photoPreviews = [];
      this.chef.photos = [];
    },
    // processAnswers(e) {
    //   // console.log(e)
    //   let answers = {};
    //   answers.token = this.usertoken; 
    //   answers.data = e;
    //   answers.task = this.gm.taskType;
    //   answers.cname = this.cname; 
    //   answers.property = this.gm.property;
    //   this.$axios.post(`${this.gmCheckListURL}`, answers)
    //     .then(res => {
    //       if(res.status === 201) {
    //         console.log(res)
    //         // let data = { 
    //         //     room: res.data.data[0].room, 
    //         //     room_type: res.data.data[0].room_type,
    //         //     guest_type: res.data.data[0].guest_type, 
    //         //     questions: res.data.data[0].questions,
    //         //     occupants_info: res.data.data[0].occupants_info
    //         //   };
    //         // this.gmRecordedAnswers.push(data);
    //         this.showToast('Success', 'success', 'Congratulations, your answers have been successfully processed');
    //       }
    //     })
    //     .catch(() => this.showToast('Error', 'danger', 'Sorry, there was an error and answers could not be proccessed'));
    // },
    // async getTaskTypes() {
    //   const { data: { data } } = await this.$axios
    //     .get(`${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/GeneralManager/tasktypes/${this.usertoken}/${this.cname}`)
    //   this.taskTypes = data
    // },
    showToast(title = '', variant = '', body) {
      this.$bvToast.toast(body, {
        title: `${ title || 'Information' }`,
        variant: `${ variant || 'default' }`,
        toaster: 'b-toaster-top-center',
        solid: true
      })
    },
  }
}
</script>

<style scoped>
.fade-slide-enter-active, .fade-slide-leave-active {
  transition: all .3s ease;
}

.fade-slide-enter, .fade-slide-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style>