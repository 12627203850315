<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h3 class="text-success">Cleaned Rooms (Today)</h3>
        
        <table v-if="recordedAnswers.length" class="table mb-5">
          <thead>
            <tr>
              <th>Room</th>
              <th>Room Status</th>
              <th class="text-center">Pax</th>
              <th class="text-center">Special Request?</th>
              <th class="text-center">Any Broken?</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(a, i) in recordedAnswers" :key="i">
              <td>{{ a.room }}</td>
              <td>{{ a.room_type }}</td>
              <td class="text-center">{{ a.occupants_info.pax || '---' }}</td>
              <td class="text-center">{{ a.occupants_info.special_requests || '---' }}</td>
              <td class="text-center">
                <span v-for="(question, q) in a.questions" :key="q">
                  <font-awesome-icon 
                    v-if="question.question === 'Anything broken?'"
                    :title="question.answer"
                    :icon="['fas','bell']" class="blink text-danger"
                  ></font-awesome-icon>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <p v-else class="bg-warning text-white mb-5 py-2 px-4 badge badge-pill">No clean rooms yet, Select property and get booked rooms to start cleaning!</p>

        <h3 class="text-secondary">Rooms Checklist 
          <span v-if="Object.keys(bookedRoomData).length">for {{ bookedRoomData.guest_type }} Guest Room</span>
        </h3>
        <hr>
        <form ref="requestBookedRooms" id="requestBookedRooms">
          <div class="row">
            <div class="mb-3 col-md-4 col-lg-3">
              <label for="property">Select Property</label>
              <select name="property" v-model="bookedRoom.property" id="property" class="form-control">
                <option value="" selected disabled>--- Select Property ---</option>
                <option v-for="(property, i) in clientProperties" :value="property" :key="i">{{ property }}</option>
              </select>
            </div>
            <div class="mb-3 col-md-4 col-lg-3">
              <label for="date">Todays Date</label>
              <input type="date" v-model="bookedRoom.date" name="date" id="date" class="form-control" readonly>
            </div>
            
            <div v-if="!bookedRooms.length" class="mb-3 col-md-12 col-lg-6 pt-lg-3">
              <button @click.prevent="validateIntialData" class="btn btn-block btn-primary mt-3">Get Rooms</button>
            </div>
            <div v-if="bookedRooms.length" class="mb-3 col-md-4 col-lg-3">
              <label for="session">Select session</label>
              <select name="session" v-model="bookedRoom.session" id="session" class="form-control">
                <option value="" selected disabled>--- Select Session ---</option>
                <option v-for="session in bookedRoom.sessions" :value="session" :key="session">{{ session }}</option>
              </select>
            </div>
            <div v-if="bookedRooms.length" class="mb-3 col-md-6 col-lg-3">
              <label for="room">Select Room</label>
              <select name="room" v-model="bookedRoom.room" id="room" class="form-control">
                <option value="" selected disabled>--- Select Room ---</option>
                <option v-for="(room, i) in bookedRooms" :value="room" :key="i">{{ room }}</option>
              </select>
            </div>
            
          </div>
        </form>
        <form v-if="reqRoomData.questions.length" ref="checklistForm" id="checklistForm">
          <Question
            :questions="reqRoomData.questions" 
            @getAnswers="processAnswers($event)" 
          />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import Arrival from './Questions/Arrival.vue'
// import InHouse from './Questions/InHouse.vue'
import Question from './Question.vue'

export default {
  components: { Question },
  data() {
    return {
      bookedRoom: { 
        property: '', 
        date: new Date().toISOString().substr(0, 10), 
        sessions: ["Day2Day", "TurnDown"],
        session:'',
        room:'' 
      }, 
      bookedRooms: [], roomQuestions: [],
      propertiesURL: `${this.$URLaddress}/${this.platform}/frontoffice/API/index.php/Settings`,
      bookedRoomsURL: `${this.$URLaddress}/${this.platform}/frontoffice/API/index.php/rooms_booked`,
      checklistURL: `${this.$URLaddress}/${this.platform}/frontoffice/API/index.php/checklist`,
      recordedAnswerURL: `${this.$URLaddress}/${this.platform}/frontoffice/API/index.php/Answers`,
      clientProperties: [], bookedRoomData: {}, recordedAnswers: [],
      reqRoomData: { questions: [], roomInfo: {}, roomType:''  },
    }
  },
  mounted() {
    // console.log('userPlatform: ', this.userPlatform)
    this.propertiesURL = `${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/Settings`;
    this.bookedRoomsURL = `${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/rooms_booked`;
    this.checklistURL = `${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/checklist`;
    this.recordedAnswerURL = `${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/Answers`;
    this.getProperties();
    this.getRecordedAnswers();
  },
  computed: {
    ...mapGetters(['userProperties','usertoken', 'userPlatform', 'platformURL', 'cname']),
    property: function() { return this.bookedRoom.property },
    date: function() { return this.bookedRoom.date },
    room: function() { return this.bookedRoom.room },
    session: function() { return this.bookedRoom.session }
  },
  watch: {
    property: function(property) {
      this.bookedRoomData = {}; this.bookedRooms = []; this.bookedRoom.room = ''; this.bookedRoom.session = '';
      this.validateSecondaryData(property, this.bookedRoom.date, this.bookedRoom.room);
    },
    date: function(date) {
      this.bookedRoomData = {}; this.bookedRooms = []; this.bookedRoom.room = '';
      this.validateSecondaryData(this.bookedRoom.property, date, this.bookedRoom.room);
    },
    session: function(session) {
      if(session) this.bookedRoom.room = '';
    },
    room: function(room) {
      this.bookedRoomData = {}; this.reqRoomData.questions = [];
      this.validateSecondaryData(this.bookedRoom.property, this.bookedRoom.date, room, this.bookedRoom.session);
    },
  },
  methods: {
    validateIntialData() {
      this.requestBookedRooms();
    },
    validateSecondaryData(property, date, room, session) {
      this.requestQuestions(property, date, room, session);
    },
    requestBookedRooms() {
      let formData = new FormData(this.$refs.requestBookedRooms);
      formData.append('cname', this.cname);
      formData.append('token', this.usertoken);
      this.$axios.post(`${this.bookedRoomsURL}`, formData)
        .then(res => {
          if(res.data.data.length) {
            this.bookedRooms = res.data.data;
            this.bookedRooms.forEach(b => {
              this.recordedAnswers.forEach(r => {
                if(b === r.room) {
                  this.bookedRooms.splice(this.bookedRooms.indexOf(b), 1);
                }
              })
            });
            if(this.bookedRooms.length < 1) this.showToast('Please be informed', 'info', 'All booked rooms have already been cleaned');
            // console.log('booked rooms: ', this.bookedRooms);
          } else {
            this.showToast('Sorry, no booked rooms', 'warning', 'Change property to proceed..!');
          }
        })
        .catch(err => console.log(err));
    },
    async requestQuestions(property, date, room, session) {
      if(property && date && room && session) {
        const { data: { data: { questions, room_info, room_type } } } = await this.$axios.get(`${this.platformURL}/frontoffice/API/index.php/checklist/${this.usertoken}/${this.cname}/${property}/${date}/${room}/${session}`);
      
          this.reqRoomData.questions = questions;
          this.reqRoomData.roomInfo = room_info;
          this.reqRoomData.roomType = room_type;
      }
    },
    async getRecordedAnswers() {
      try {
        const { data: { data } } = await this.$axios
        .get(`${this.platformURL}/frontoffice/API/index.php/Answers/${this.usertoken}/${this.cname}/`)
        this.recordedAnswers = data;
      } catch(e) {
        console.log(`Sorry, there is an Error. ${e.response.data.message}`)
      }
        
        // .then(res => this.recordedAnswers = res.data.data)
        // .catch(err => console.log(err))
    },
    async getProperties() {
      const { data: { data } } = await this.$axios
      .get(`${this.platformURL}/frontoffice/API/index.php/Properties/${this.usertoken}/${this.cname}`);
      this.clientProperties = data;
    },
    processAnswers($e) {
      let answers = {};
      answers.token = this.usertoken; answers.data = $e;
      answers.cname = this.cname; answers.property = this.bookedRoom.property;
      answers.date = this.bookedRoom.date; answers.room = this.bookedRoom.room;
      answers.room_type = this.reqRoomData.roomType;
      this.$axios.post(`${this.checklistURL}`, answers)
        .then(res => {
          if(res.status === 201) {
            // console.log(res.data)
            let data = { 
                room: res.data.data[0].room, 
                room_type: res.data.data[0].room_type,
                guest_type: res.data.data[0].guest_type, 
                questions: res.data.data[0].questions,
                occupants_info: res.data.data[0].occupants_info
              };
            this.recordedAnswers.push(data);
            this.bookedRoomData = {}; this.bookedRooms = []; this.bookedRoom.room = ''; this.bookedRoom.session = '';
            this.showToast('Success', 'success', 'Congratulations, your answers have been successfully processed');
          }
        })
        .catch(() => this.showToast('Error', 'danger', 'Sorry, there was an error and answers could not be proccessed'));
    },
    showToast(title = '', variant = '', body) {
      this.$bvToast.toast(body, {
        title: `${ title || 'Information' }`,
        variant: `${ variant || 'default' }`,
        toaster: 'b-toaster-top-center',
        solid: true
      })
    },
  }
}
</script>

<style scoped>
  @keyframes blink {
    0% { opacity: 0 }
    20% { opacity: 0 }
    50% { opacity: 1 }
    80% { opacity: 0 }
    100% { opacity: 0 }
  }
  .blink {
    animation: blink 1s infinite;
    transition: all .3s ease;
  }
  .blink:hover {
    animation: none;
    transform: scale(1.5);
    cursor: pointer;
  }
</style>