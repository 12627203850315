<template>
  <div class="row">
    <div class="col-12">
      <h3 class="mb-3">
        Orders
        <b-button pill v-b-modal.order class="float-right">Add Order</b-button>
      </h3>
      <table class="table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Property</th>
            <th class="text-center">Items</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(order, i) in orders" :key="order.id">
            <td>{{ order.date }}</td>
            <td>{{ order.property }}</td>
            <td class="text-center">
              <a href="#" 
                class="badge badge-secondary badge-pill" 
                @click.prevent="loadOrderedItems(i)" 
                title="View ordered items"
              >{{ order.items.length }}</a>
            </td>
            <td><a href="#" title="Edit this Order" @click.prevent="loadOrder(i)">Edit</a></td>
            <td><a href="#" :title="`Delete Order dated ${order.date}`" @click.prevent="deleteOrder(i)">Delete</a></td>
          </tr>
        </tbody>
      </table>

      <!-- Order Modal -->
      <b-modal id="order" centered scrollable size="lg">
        <template #modal-header>
          <h5>{{ edit ? `Edit Order, Dated ${order.date}` : 'Add a New Order' }}</h5>
          <b-button size="sm" variant="outline-danger" @click="$bvModal.hide('order'); clearForm()">Close</b-button>
        </template>
        
        <form ref="orderForm" id="orderForm">
          <div class="row">
            <div class="col-6 form-group">
              <label for="property">Select Property</label>
              <select name="property" v-model="order.property" id="property" class="form-control" :readonly="{ true : edit }">
                <option value="" disabled selected>--- Select Property ---</option>
                <option v-for="property in allowedProperties" :value="property" :key="property">{{ property }}</option>
              </select>
            </div>
            <div class="col-6 form-group">
              <label for="item">Select Item</label>
              <select id="item" v-model="item.id" class="form-control" required>
                <option value="" disabled selected>--- Select Item ---</option>
                <option v-for="item in items" :value="item.id" :key="item.id">{{ item.name }}</option>
              </select>
            </div>
            <div class="col-3 form-group">
              <label for="qty">Quantity</label>
              <input type="number" v-model="item.qty" id="qty" min="1" class="form-control" required>
            </div>
            <div class="col-3">
              <p v-if="item.unit" style="margin-top: 35px">{{ item.unit }}</p>
            </div>
            <div class="col-6">
              <b-button 
                @click.prevent="addItemToOrder" 
                variant="info" 
                size="sm" 
                style="margin-top: 30px" 
                class="float-right w-100"
              >Add Item</b-button>
            </div>
          </div>
        </form>

        <!-- Table of added items -->
        <div v-if="order.items.length" class="table-responsive mt-3">
          <h5>Added <span v-if="order.items.length">{{ order.items.length }}</span> Items for Order</h5>
          <table class="table">
            <thead>
              <tr>
                <th>Item</th>
                <th>Qty</th>
                <th><span class="float-right text-danger">Remove</span></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in order.items" :key="item.id">
                <td class="w-50">{{ item.name }}</td>
                <td class="w-25">{{ item.qty }}</td>
                <td>
                  <b-button 
                    @click.prevent="removeItemInOrder(i)" 
                    variant="outline-danger" 
                    size="sm" 
                    class="float-right py-0"
                    >Remove
                  </b-button>
                  </td>
              </tr>
            </tbody>
          </table>
        </div>

        
        <template #modal-footer>
          <div class="w-100">
            <p class="float-left text-black-50 small pt-1">Click the button clear or add new Order</p>
            <b-button variant="primary" size="sm" class="float-right" @click="validateData">
              {{ edit ? 'Save Changes' : 'Add Order' }}
            </b-button>
            <b-button variant="outline-secondary" size="sm" class="float-right mr-3" @click="clearForm">Clear</b-button>
          </div>  
        </template> 
      </b-modal>

      <!-- Ordered Items Modal -->
      <b-modal hide-footer centered scrollable id="orderedItems">
        <template #modal-header>
          <h5>
            View 
            <span v-if="order.items.length">{{ order.items.length }}</span> 
            Ordered Items 
            <span v-if="order.date">on {{ order.date }}</span>
          </h5>
          <b-button size="sm" variant="outline-danger" @click="$bvModal.hide('orderedItems'); clearForm()">Close</b-button>
        </template>
        
        <table class="table">
          <thead>
            <tr>
              <th>Item Name</th>
              <th class="text-center">Quantity</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in order.items" :key="item.id">
              <td>{{ item.name }}</td>
              <td class="text-center">{{ item.qty }}</td>
            </tr>
          </tbody>
        </table>
        
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      orders: [], items: [], item: { id: '', name: '', qty: '', unit: '' },
      order: {
        id: '', date: '', property: '', cname: '', items: []
      },
      edit: false, allowedProperties: [],
      allowedPropertiesURL: '', ordersURL: '', itemsURL: ''
    }
  },
  mounted() {
    this.allowedPropertiesURL = `${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/Properties/allowed`;
    this.ordersURL = `${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/Inventory/orders`;
    this.itemsURL = `${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/Inventory/items`;
    this.getOrders(); this.getItems(); this.getAllowedProperties();
  },
  watch: {
    itemID: function(id) {
      if(id) {
        this.item.unit = this.items.find(i => i.id === id).unit
        this.item.name = this.items.find(i => i.id === id).name
      } else {
        this.item.unit = ''
        this.item.name = ''
      }
    }
  },
  computed: {
    ...mapGetters([
      'usertoken', 'userPlatform', 'platformURL', 'cname'
    ]),
    itemID: function() { return this.item.id }
  },
  methods: {
    validateData() {
      this.sendOrder();
    },
    sendOrder() {
      if(this.edit) {
        // edit order
        let formData = new FormData(this.$refs.orderForm)
        formData.append('_method', "PUT")

        for(let i = 0; i < this.order.items.length; i++) {
          formData.append(`data[${i}][item_id]`, this.order.items[i].id)
          formData.append(`data[${i}][qty]`, this.order.items[i].qty)
        }

        this.$axios.post(`${this.ordersURL}/${this.usertoken}/${this.cname}/${this.order.id}`, formData)
          .then(res => { console.log(res)
            if(res.statusText === 'OK') {
              this.clearItem(); this.clearForm(); this.$bvModal.hide('order');
            }
          }
        ).catch(err => console.log(err))
      } else {
        // add order
        let formData = new FormData(this.$refs.orderForm)
        formData.append('token', this.usertoken)
        formData.append('cname', this.cname)

        for(let i = 0; i < this.order.items.length; i++) {
          formData.append(`data[${i}][item_id]`, this.order.items[i].id)
          formData.append(`data[${i}][qty]`, this.order.items[i].qty)
        }

        this.$axios.post(`${this.ordersURL}`, formData).then(res => {
          if(res.statusText === 'OK') {
            this.clearItem(); this.clearForm(); this.$bvModal.hide('order');
          }
        }).catch(err => console.log(err))
      }
    },
    addItemToOrder() {
      const itemToAdd = this.order.items.find(i => i.id === this.item.id);
      if(itemToAdd === undefined) {
        this.order.items.push({ id: this.item.id, name: this.item.name, qty: this.item.qty });
        this.clearItem();
      } else {
        alert(`${itemToAdd.name} already exist`)
      }
    },
    removeItemInOrder(i) {
      if(confirm('Are you sure you want to remove this item from Order ?')) {
        this.order.items.splice(i, 1);
      }
    },
    async getOrders() {
      const { data: { data } } = await this.$axios.get(`${this.ordersURL}/${this.usertoken}/${this.cname}`)
        this.orders = data;
    },
    async getItems() {
      const { data: { data } } = await this.$axios.get(`${this.itemsURL}/${this.usertoken}/${this.cname}`)
      this.items = data;
    },
    async getAllowedProperties() {
      const { data : { data : { properties_allowed } } } = await this.$axios
        .get(`${this.allowedPropertiesURL}/${this.usertoken}/${this.cname}`);
      this.allowedProperties = properties_allowed
    },
    loadOrder(i) {
      this.edit = true; this.$bvModal.show('order');
      this.order.id = this.orders[i].id;
      this.order.date = this.orders[i].date;
      this.order.cname = this.orders[i].cname;
      this.order.property = this.orders[i].property;
      this.order.items = this.orders[i].items;
    },
    loadOrderedItems(i) {
      this.$bvModal.show('orderedItems')
      this.order.id = this.orders[i].id;
      this.order.date = this.orders[i].date;
      this.order.cname = this.orders[i].cname;
      this.order.property = this.orders[i].property;
      this.order.items = this.orders[i].items;
    },
    deleteOrder(i) {
      if(confirm('Are you sure you want to delete this Order ?')) {
        this.$axios.delete(`${this.ordersURL}/${this.usertoken}/${this.cname}/${this.orders[i].id}`)
        .then(res => {
          if(res.statusText === 'OK') {
            this.orders.splice(i, 1);
          }
        })
        .catch(err => console.log(err))
      }
    },
    clearItem() {
      this.item.id = ''; this.item.name = ''; this.item.qty = ''; this.item.unit = '';
    },
    clearForm() {
      this.edit = false;
      this.order.id = ''; this.order.date = ''; this.order.property = ''; 
      this.order.cname = ''; this.order.items = [];
    }
  }
}
</script>