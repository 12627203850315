<template>
  <div class="row">
    <div class="col-md-6 offset-md-3">
      <div class="card">
        <h3 class="card-header text-center">Respad Login</h3>
        <div class="card-body p-5">
          <form ref="loginForm" @submit.prevent="validateData">
            <div class="form-group">
              <label for="username">Enter Username</label>
              <input type="text" v-model="user.name" name="username" id="name" class="form-control" autocomplete required>
            </div>
            <div class="form-group">
              <label for="password">Enter Password</label>
              <input type="password" v-model="user.password" name="password" id="password" class="form-control" autocomplete required>
            </div>
            <div class="form-group">
              <select name="platform" v-model="user.platform" id="platform" class="form-control" required>
                <option value="" selected disabled>--- Select Platform ---</option>
                <option v-for="(platform, i) in platforms" :value="platform" :key="i">{{ platform }}</option>
              </select>
            </div>
            <button class="btn btn-lg btn-block btn-primary">Login</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    let platform = "default";
    return {
      user: { name:'', password:'',  platform: ''},
      loginURL: `${this.$URLaddress}/${platform}/frontoffice/API/index.php/Login`,
      platforms: [ 'default', 'bouganivillea', 'blue', 'respad', 'respadplus', 'central' ],
    }
  },
  mounted() {
    //
  },
  computed: {
    platform: function() {
      return this.user.platform;
    }
  },
  watch: {
    platform: function(platform) {
      console.log(platform);
      this.loginURL = `${this.$URLaddress}/${platform}/frontoffice/API/index.php/Login`;
    },
  },
  methods: {
    validateData() {
      this.checkCredentials();
    },
    Auth() {
      if(this.usertoken !== null) { 
        this.$store.dispatch('logout', { header: false });
        this.$router.replace('/login');
      } else {
        this.$router.replace('/dashboard');
      }
    },
    checkCredentials() {
      let formData = new FormData(this.$refs.loginForm);
      
      // for (let p of formData) alert(p)

      this.$axios.post(`${this.loginURL}`, formData)
        .then(res => { //console.log(res);
          if(res.status) {
            this.$store.dispatch('login', { 
              properties: res.data.data.properties,
              allowedProperties: res.data.properties_allowed,
              token: res.data.data.token, 
              cname: res.data.data.cname,
              platform: this.user.platform,
              // QuestionPrivileges: res.data.data.privileges.Questions,
              // AnswerPrivileges: res.data.data.privileges.Answers,
            });
            this.$router.push('/dashboard').catch(() => { 
                location.reload()
              }
            );
          }
        }).catch(() => {
          this.showToast('Error', 'danger', 'Please check your username & password');
        })
    },
    showToast(title = '', variant = '', body) {
      this.$bvToast.toast(body, {
        title: `${ title || 'Information' }`,
        variant: `${ variant || 'default' }`,
        toaster: 'b-toaster-top-center',
        solid: true
      })
    },
  }
}
</script>