<template>
  <div class="row">
    <div class="col-12">
      <h3 class="mb-3">
        Inventory Items
        <b-button pill v-b-modal.items class="float-right" data-toggle="modal" data-target="#itemsModal">Add Item</b-button>
      </h3>
      <table class="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Min Qty</th>
            <th>Department</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in items" :key="item.id">
            <td>{{ item.name }}</td>
            <td>{{ item.type }}</td>
            <td>{{ item.min_qty }} {{ item.unit }}</td>
            <td>{{ item.department.name }}</td>
            <td><a href="#" :title="`Edit ${item.name} details`" @click.prevent="loadItem(i)">Edit</a></td>
            <td><a href="#" :title="`Delete ${item.name} from inventory`" @click.prevent="confirmItemDelete(i)">Delete</a></td>
          </tr>
        </tbody>
      </table>

      <!-- Modal -->
      <b-modal centered scrollable id="items">
          <template #modal-header>
            <h5>{{ edit ? 'Edit this Item' : 'Add a New Item' }}</h5>
            <b-button size="sm" variant="outline-danger" @click="$bvModal.hide('items'); clearForm()">Close</b-button>
          </template>
          <form id="itemsForm" ref="itemsForm">
            <input type="hidden" name="cname" id="cname" :value="cname" class="form-control">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="department_id">Select Department</label>
                <select name="department_id" v-model="item.departmentID" id="department_id" class="form-control">
                  <option value="" selected disabled>--- Select Department ---</option>
                  <option v-for="department in departments" :value="department.id" :key="department.id">{{ department.name }}</option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label for="category_id">Select Category</label>
                <select name="category_id" v-model="item.categoryID" id="category_id" class="form-control">
                  <option value="" selected disabled>--- Select Category ---</option>
                  <option v-for="category in categories" :value="category.id" :key="category.id">{{ category.name }}</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <label for="name">Item Name</label>
                <input type="text" name="name" v-model="item.name" id="name" class="form-control">
              </div>
              <div class="form-group col-md-6">
                <label for="type">Item Type</label>
                <select name="type" v-model="item.type" id="type" class="form-control">
                  <option value="" selected disabled>--- Select Type ---</option>
                  <option value="Consumable">Consumable Item</option>
                  <option value="Non-Consumable">Non-Consumable</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-lg-6">
                <label for="min_qty">Minimum Quantity</label>
                <input type="number" name="min_qty" v-model="item.minQty" min="1" id="min_qty" class="form-control">
              </div>
              <div class="form-group col-lg-6">
                <label for="unit">Unit</label>
                <input type="text" name="unit" v-model="item.unit" id="unit" class="form-control">
              </div>
            </div>
          </form>
          <template #modal-footer>
            <div class="w-100">
              <p class="float-left text-black-50 small pt-1">Click the button clear or add a new Item</p>
              <b-button variant="primary" size="sm" class="float-right" @click="validateData">
                {{ edit ? 'Save Changes' : 'Add Item' }}
              </b-button>
              <b-button variant="outline-secondary" size="sm" class="float-right mr-3" @click="clearForm">Clear</b-button>
            </div>  
          </template> 
        </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      items: [], departments: [], categories: [], edit: false,
      item: { id: '', departmentID:'', categoryID: '', name:'', type:'', minQty: '', unit: '', cname: '' },
      itemsURL: '', categoriesURL:'', departmentsURL: ''
      }
    },
    mounted() {
      this.departmentsURL = `${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/Preferences/departments`;
      this.categoriesURL = `${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/Preferences/categories`;
      this.itemsURL = `${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/Inventory/items`;
      this.getItems(); this.getCategories(); this.getDepartments()
    },
    computed: {
      ...mapGetters([
        'usertoken', 'userPlatform', 'platformURL', 'cname'
      ]),
    },
    methods: {
      validateData() {
        this.saveItem();
      },
      async saveItem() {
        if(this.edit) {
          const formData = new FormData(this.$refs.itemsForm)
          formData.append('_method', 'PUT');
          const { data: { 
            data: { 
                cname, id, min_qty, name, type, unit, category, department } 
              } 
            } = await this.$axios.post(`${this.itemsURL}/${this.usertoken}/${this.cname}/${this.item.id}`, formData)
            let itemToEdit = this.items.find(i => i.id === id);
            itemToEdit.cname = cname; itemToEdit.min_qty = min_qty; itemToEdit.name = name;
            itemToEdit.department = department; itemToEdit.category = category; itemToEdit.type = type;
            itemToEdit.unit = unit; this.$bvModal.hide('items'); this.clearForm();
            this.showToast('Congrats', 'success', 'Item details have been successfully updated');
        } else {
          // add item
          const formData = new FormData(this.$refs.itemsForm)
          formData.append('token', this.usertoken);
          const { data: { data } } = await this.$axios.post(`${this.itemsURL}`, formData)
          this.items.push(data); this.$bvModal.hide('items'); this.clearForm();
          this.showToast('Congrats', 'success', 'Item have been successfully added');
        }
      },
      async getItems() {
        const { data: { data } } = await this.$axios.get(`${this.itemsURL}/${this.usertoken}/${this.cname}`)
        this.items = data;
      },
      async getCategories() {
        const { data: { data } } = await this.$axios.get(`${this.categoriesURL}/${this.usertoken}/${this.cname}`)
        this.categories = data;
      },
      async getDepartments() {
        const { data: { data } } = await this.$axios.get(`${this.departmentsURL}/${this.usertoken}/${this.cname}`)
        this.departments = data
      },
      loadItem(i) {
        this.edit = true; this.$bvModal.show('items');
        this.item.id = this.items[i].id;
        this.item.departmentID = this.items[i].department.ID;
        this.item.categoryID = this.items[i].category.ID;
        this.item.name = this.items[i].name;
        this.item.type = this.items[i].type;
        this.item.minQty = this.items[i].min_qty;
        this.item.unit = this.items[i].unit;
        this.item.cname = this.items[i].cname;
      },
      confirmItemDelete(i) {
      
        let confirmed = null;
        let id = this.items[i].id;
        this.$bvModal.msgBoxConfirm(`Are you sure you want to permanently delete ${this.items[i].name} from inventory ?`, {
          title: 'Please Confirm',
          size: 'sm',
          // buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(value => { console.log(value)
            let vm = this;
            confirmed = value;
            // delete item if confirmed (yes)
            if(confirmed) {
              vm.$axios.delete(`${vm.platformURL}/frontoffice/API/index.php/Inventory/items/${this.usertoken}/${this.cname}/${id}`)
                .then(res => {
                  if(res.status === 200) {
                    this.showToast('Success', 'success', 'Item have been successfully deleted');
                    vm.items.splice(i,1);
                  }
                })
                .catch(() => { 
                  this.showToast('Sorry', 'danger', 'Something went wrong, we couldn\'t delete the Item. Please try again later');
                }
              );
            }
          })
          .catch(err => {
            this.showToast('Error', 'danger', err.message);
          }
        );
      },
      showToast(title = '', variant = '', body) {
        this.$bvToast.toast(body, {
          title: `${ title || 'Information' }`,
          variant: `${ variant || 'default' }`,
          toaster: 'b-toaster-top-center',
          solid: true
        })
      },
      clearForm() {
        this.edit = false; 
        this.item.id = ''; this.item.name = ''; this.item.type = '';
        this.item.minQty = ''; this.item.unit = '';
        this.item.departmentID = '';
        this.item.categoryID = '';
      }
    }
  }
</script>

<style scoped>
/*  */
</style>