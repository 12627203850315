<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h3 class="mb-4">Feedback Checklist</h3>
        <form>
          <div class="row">
            <div class="col-10 pt-4 form-group">
              <button @click.prevent="validateData" class="btn btn-lg btn-warning w-100 text-white">Please request questions here.</button>
            </div>
            <div class="col-2 pt-4 form-group">
              <button @click.prevent="clearQuestions" class="btn btn-lg btn-secondary w-100">Clear</button>
            </div>
          </div>
        </form>
        <Question 
          ref="questionComponent"
          :questions="feedbackQuestions"
          @getAnswers="processAnswers"
        />
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import Question from '@/components/Feedbacks/Question.vue';

export default {
  components: {
    Question
  },
  data() {
    return {
      // gm: {
      //   property: '', taskType: ''
      // },
      // taskTypes: [],
      // allowedPropertiesURL: '', 
      feedbackQuestionsURL: '',
      feedbackCheckListURL: '',
      feedbackQuestions: [],
      feedbackRecordedAnswers: [],
      allowedProperties: []
    }
  },
  mounted() {
    // this.allowedPropertiesURL = `${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/Properties/allowed`;
    this.feedbackQuestionsURL = `${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/Feedback/questions`;
    this.feedbackCheckListURL = `${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/Feedback/checklist`;
    // this.getAllowedProperties();
    // this.getTaskTypes();
  },
  computed: {
    ...mapGetters([
      'userProperties', 'userAllowedProperties','usertoken', 
      'userPlatform', 'platformURL', 'cname'
    ]),
  },
  methods: {
    validateData() {
      this.requestQuestions()
    },
    // getAllowedProperties() {
    //   this.$axios.get(`${this.allowedPropertiesURL}/${this.usertoken}/${this.cname}`)
    //     .then(res => {
    //       if(res.status === 200) this.allowedProperties = res.data.data.properties_allowed;
    //     })
    // },
    requestQuestions() {
      this.$axios
        .get(`${this.feedbackQuestionsURL}/${this.usertoken}/${this.cname}`)
        .then(res => { if(res.status === 200) {
          this.feedbackQuestions = res.data.data;
          // e.target.setAttribute('disabled', true);
        } 
      })
    },
    clearQuestions() {
      this.$refs.questionComponent.theQuestions = []
    },
    processAnswers(e) {
      // console.log(e)
      let answers = {};
      answers.token = this.usertoken;
      answers.data = e;
      answers.cname = this.cname;
      this.$axios.post(`${this.feedbackCheckListURL}`, answers)
        .then(res => {
          if(res.status === 201) {
            console.log(res)
            // let data = { 
            //     room: res.data.data[0].room, 
            //     room_type: res.data.data[0].room_type,
            //     guest_type: res.data.data[0].guest_type, 
            //     questions: res.data.data[0].questions,
            //     occupants_info: res.data.data[0].occupants_info
            //   };
            // this.gmRecordedAnswers.push(data);
            this.showToast('Success', 'success', 'Congratulations, your answers have been successfully processed');
          }
        })
        .catch(() => this.showToast('Error', 'danger', 'Sorry, there was an error and answers could not be proccessed'));
    },
    showToast(title = '', variant = '', body) {
      this.$bvToast.toast(body, {
        title: `${ title || 'Information' }`,
        variant: `${ variant || 'default' }`,
        toaster: 'b-toaster-top-center',
        solid: true
      })
    }
  }
}
</script>