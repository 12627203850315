<template>
  <div>
    <h3>Dashboard</h3>
    <p class="text-black-50">Dashboard is under construction. You may use links below;</p>
    <hr>
    <h4 class="text-black-50">Staff Related</h4>
    <ul class="list-unstyled">
      <li><router-link to="/questions">Questions</router-link></li>
      <li><router-link to="/checklist">Checklist</router-link></li>
    </ul>
    <hr>
    <h4 class="text-black-50">Manager/s Related</h4>
    <ul class="list-unstyled">
      <li><router-link to="/gm/questions">Questions</router-link></li>
      <li><router-link to="/gm/checklist">Checklist</router-link></li>
    </ul>
    <h4 class="text-black-50">Chief/s Related</h4>
    <ul class="list-unstyled">
      <li><router-link to="/meals">Daily Meals</router-link></li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      //
    }
  },
  computed: {
    ...mapGetters([ 'usertoken' ])
  },
  mounted() {
    this.Auth();
  },
  methods: {
    Auth() {
      if(this.usertoken === null) {
        console.log('dashboard...');
        this.$router.push('/login');
        this.$store.dispatch('logout');
      }
    }
  }
}
</script>

<style scoped>
/*  */
</style>