<template>
  <div class="row">
    <div class="col-12">
      <h3 class="mb-3">
        Departments
        <b-button pill v-b-modal.department class="float-right">Add Department</b-button>
      </h3>
      <table class="table">
        <thead>
          <tr>
            <th class="w-75">Name</th>
            <th>Edit</th>
            <th class="text-danger">Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(dep, i) in departments" :key="dep.id">
            <td>{{ dep.name }}</td>
            <td><a @click.prevent="loadDepartment(i)" href="#">Edit</a></td>
            <td><a @click.prevent="deleteDepartment(i)" class="text-danger" href="#">Delete</a></td>
          </tr>
        </tbody>
      </table>

      <b-modal id="department" scrollable centered>
        <template #modal-header>
          <h4>{{ edit ? 'Edit Department' : 'Add new Department' }}</h4>
          <b-button size="sm" variant="outline-danger" @click="$bvModal.hide('department'); clearDepartmentForm()">Close</b-button>
        </template>
        <form ref="departmentForm" id="departmentForm">
          <label for="name">Name</label>
          <input type="text" v-model="department.name" name="name" id="name" class="form-control">
        </form>
        <template #modal-footer>
          <div class="w-100">
            <p class="float-left text-black-50 small pt-1">Click the button to add a new Deparment</p>
            <b-button variant="primary" size="sm" class="float-right" @click="validateData">
              {{ edit ? 'Save Changes' : 'Add Department' }}
            </b-button>
            <b-button variant="outline-secondary" size="sm" class="float-right mr-3" @click="clearDepartmentForm">Clear</b-button>
          </div>  
        </template>
      </b-modal>
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      departments: [],
      department: { id: '', name: '' },
      edit: false,
      departmentsURL: ''
    }
  },
  computed: {
    ...mapGetters([ 'usertoken','cname', 'userProperties', 'userPlatform', 'platformURL' ]),
  },
  mounted() {
    this.departmentsURL = `${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/Preferences/departments`;
    this.getDepartments();
  },
  methods: {
    validateData() {
      this.sendData()
    },
    async sendData() {
      if(this.edit) {
        // edit data
        let formData = new FormData(this.$refs.departmentForm)
        formData.append('_method', 'PUT')
        this.$axios.post(`${this.departmentsURL}/${this.usertoken}/${this.cname}/${this.department.id}`, formData)
          .then(res => {
            if(res.status === 200) {
              let theDep = this.departments.find(d => +d.id === +res.data.data.id);
              theDep.name = res.data.data.name; this.$bvModal.hide('department');
              this.clearDepartmentForm();
            }
          })
          .catch(err => console.log(err))
        
      } else {
        // add data
        let formData = new FormData(this.$refs.departmentForm)
        formData.append('token', this.usertoken)
        formData.append('cname', this.cname)
        const { data: { data } } = await this.$axios.post(`${this.departmentsURL}`, formData)
        this.departments.push(data); this.$bvModal.hide('department'); this.clearDepartmentForm();
      }
    },
    async getDepartments() {
      const { data: { data } } = await this.$axios.get(`${this.departmentsURL}/${this.usertoken}/${this.cname}`)
      this.departments = data
    },
    loadDepartment(i){
      this.edit = true; this.$bvModal.show('department')
      this.department.id = this.departments[i].id;
      this.department.name = this.departments[i].name;
    },
    deleteDepartment(i) {
      if(confirm('Are you sure you want to delete this department?')) {
        this.$axios.delete(`${this.departmentsURL}/${this.usertoken}/${this.cname}/${this.departments[i].id}`)
          .then(res => {
            if(res.status === 200) this.departments.splice(i, 1)
          })
          .catch(err => console.log(err))
      }
    },
    clearDepartmentForm() {
      this.edit = false; this.department.id = ''; this.department.name = '';
    }
  }
}
</script>