<template>
  <div>
    <div class="row">
      <div class="col-12">
        <form>
          <div class="row">
            <div class="col-lg-4 form-group">
              <label for="property">Select Property</label>
              <select name="property" v-model="gm.property" id="property" class="form-control">
                <option value="" selected disabled>--- Select Property ---</option>
                <option v-for="property in allowedProperties" :value="property" :key="property">{{ property }}</option>
              </select>
            </div>
            <div class="col-lg-4 form-group">
              <label for="task">Select Task Type</label>
              <select name="task" v-model="gm.taskType" id="task" class="form-control">
                <option value="">--- Select Task Type ---</option>
                <option v-for="t in taskTypes" :value="t.ID" :key="t.ID">{{ t.name }}</option>
              </select>
            </div>
            <div class="col-lg-4 pt-4 form-group">
              <button @click.prevent="validateData" class="btn btn-lg btn-primary w-100">Request Questions</button>
            </div>
          </div>
        </form>
        <Question 
          :property="gm.property"
          :taskType="+gm.taskType"
          :questions="gmQuestions" 
          @getAnswers="processAnswers"
        />
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import Question from '@/components/GeneralManager/Question.vue';
export default {
  components: {
    Question
  },
  data() {
    return {
      gm: {
        property: '', taskType: ''
      },
      taskTypes: [],
      allowedPropertiesURL: '', 
      gmQuestionsURL: '',
      gmCheckListURL: '',
      gmQuestions: [],
      gmRecordedAnswers: [],
      allowedProperties: []
    }
  },
  mounted() {
    this.allowedPropertiesURL = `${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/Properties/allowed`;
    this.gmQuestionsURL = `${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/GeneralManager/questions`;
    this.gmCheckListURL = `${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/GeneralManager/checklist`;
    this.getAllowedProperties();
    this.getTaskTypes();
  },
  computed: {
    ...mapGetters([
      'userProperties', 'userAllowedProperties','usertoken', 
      'userPlatform', 'platformURL', 'cname'
    ]),
  },
  methods: {
    validateData() {
      this.requestQuestions()
    },
    getAllowedProperties() {
      this.$axios.get(`${this.allowedPropertiesURL}/${this.usertoken}/${this.cname}`)
        .then(res => {
          if(res.status === 200) this.allowedProperties = res.data.data.properties_allowed;
        })
    },
    requestQuestions() {
      this.$axios.get(`${this.gmQuestionsURL}/${this.usertoken}/${this.cname}/0/${this.gm.property}/${this.gm.taskType}`)
        .then(res => {
          if(res.status === 200) this.gmQuestions = res.data.data
        })
    },
    processAnswers(e) {
      // console.log(e)
      let answers = {};
      answers.token = this.usertoken;
      answers.data = e;
      answers.task = this.gm.taskType;
      answers.cname = this.cname; 
      answers.property = this.gm.property;
      this.$axios.post(`${this.gmCheckListURL}`, answers)
        .then(res => {
          if(res.status === 201) {
            console.log(res)
            // let data = { 
            //     room: res.data.data[0].room, 
            //     room_type: res.data.data[0].room_type,
            //     guest_type: res.data.data[0].guest_type, 
            //     questions: res.data.data[0].questions,
            //     occupants_info: res.data.data[0].occupants_info
            //   };
            // this.gmRecordedAnswers.push(data);
            this.showToast('Success', 'success', 'Congratulations, your answers have been successfully processed');
          }
        })
        .catch(() => this.showToast('Error', 'danger', 'Sorry, there was an error and answers could not be proccessed'));
    },
    async getTaskTypes() {
      const { data: { data } } = await this.$axios
        .get(`${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/GeneralManager/tasktypes/${this.usertoken}/${this.cname}`)
      this.taskTypes = data
    },
    showToast(title = '', variant = '', body) {
      this.$bvToast.toast(body, {
        title: `${ title || 'Information' }`,
        variant: `${ variant || 'default' }`,
        toaster: 'b-toaster-top-center',
        solid: true
      })
    },
  }
}
</script>