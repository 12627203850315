<template>
  <div class="row">
    <div class="col-12 pt-3">
      <div v-if="theQuestions.length" class="card">
        <div class="card-body">
          <div 
            class="form-group d-flex align-items-center row py-1"
            v-for="(question, i) in theQuestions" :key="i"
            style="background: linear-gradient(to bottom, transparent, #eee); margin-right: 0; margin-left: 0; border-radius: 4px">
            <!-- <label :for="`question${i+1}`" class="col-1 pt-2">{{ i+1 }}</label> -->
            <label :for="`question${i+1}`" class="col-1 pt-2">{{ question.sequence }}</label>
            <div 
              :class="{ 
                  'col-9': question.answerType === 'Yes or No Answer' || question.answerType === 'Short text Answer' || question.answerType === 'Explanation Answer',
                  'col-md-5': question.checked && question.answerType === 'Short text Answer'
                }"
              >
              {{ question.question }}
            </div>
            <!-- additional answers -->
            <div class="col-1">
              <input type="checkbox" :name="`question${i+1}`" :id="`question${i+1}`" v-model="question.checked">
            </div>
            <div class="col-12 col-md-5" v-if="question.checked && question.answerType === 'Short text Answer'">
              <input type="text" name="" v-model="question.answer" id="" class="form-control">
            </div>
            <div class="col-12 mt-0" v-if="question.checked && question.answerType === 'Explanation Answer'">
              <textarea name="" v-model="question.answer" id="" cols="30" rows="2" class="form-control" placeholder="explain..."></textarea>
            </div>
          </div>
          <button @click.prevent="validateAnswers" class="btn btn-lg btn-block btn-primary">Send Answers</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    questions: Array,
    property: String,
    taskType: Number
  },
  data() {
    return {
      theQuestions: []
    }
  },
  mounted() {
    //
  },
  watch: {
    questions: function(qs) {
      if(qs.length) this.manipulateQuestions()
    },
    property: function(p) {
      console.log('Property : ',p)
      if(p) this.theQuestions = [];
    },
    taskType: function(ID) {
      if(ID) this.theQuestions = [];
    }
  },
  methods: {
    manipulateQuestions() {
      this.questions.forEach(q => {
        this.theQuestions.push({
          question: q.question,
          sequence: +q.sequence,
          answerType: q.answer_type,
          checked: false,
          answer: '',
        })
      });
    },
    validateAnswers() {
      this.sendAnswers();
    },
    sendAnswers() {
      let data = []; this.theQuestions.forEach(e => data.push(e));
      this.$emit('getAnswers', data);
    }
  }
}
</script>