<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h3 class="mb-4">Feedback Answers</h3>
        <form>
          <div class="row">
            <div class="col-lg-4 form-group">
              <label class="d-block" for="date">Select Date</label>
              <DatePicker v-model="answers.date" value-type="format" format="YYYY-MM-DD" class="w-100" />
            </div>
            <!-- <div class="col-lg-3 form-group">
              <label for="property">Select Property</label>
              <select name="property" v-model="answers.property" id="property" class="form-control">
                <option value="" selected disabled>--- Select Property ---</option>
                <option v-for="property in allowedProperties" :value="property" :key="property">{{ property }}</option>
              </select>
            </div> -->
            <!-- <div class="col-lg-3 form-group">
              <label for="task">Select Task Type</label>
              <select name="task" v-model="answers.task" id="task" class="form-control">
                <option value="" selected disabled>--- Select Task Type ---</option>
                <option v-for="task in taskTypes" :value="task.ID" :key="task.id">{{ task.name }}</option>
              </select>
            </div> -->
            <div class="col-lg-8 pt-2 form-group">
              <button @click.prevent="validateData" class="btn btn-primary w-100 mt-4">Show Answers</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div v-if="allExistingAnswers.length" class="row mt-3">
      <div class="col-12 table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>Question</th>
              <th>Answer Type</th>
              <!-- <th class="text-center">Answered ?</th> -->
              <th>Answer</th>
              <th class="w-50">Comments</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="a in allExistingAnswers" :key="a.ID">
              <td>{{ a.question }}</td>
              <td>{{ a.answer_type }}</td>
              <!-- <td class="text-center">{{ +a.checked === 1 ? 'Yes' : 'No' }}</td> -->
              <td>{{ a.answer }}</td>
              <td>{{ a.comments }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { mapGetters } from 'vuex';
export default {
  components: { DatePicker },
  data() {
    return {
      feedbackQuestionsURL: '', allowedProperties: [],
      answers: { date: '' },
      allowedPropertiesURL: '',
      allExistingAnswers: [],
    }
  },
  computed: {
    ...mapGetters([
      'userProperties', 'usertoken', 'feedbackAnswers',
      'userPlatform', 'platformURL', 'cname'
    ]),
    date: function() { return this.answers.date }
  },
  watch: {
    date: function(date) {
      if(date) this.allExistingAnswers = []
    }
  },
  mounted() {
    this.feedbackQuestionsURL = `${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/Feedback/answers`;
    this.allowedPropertiesURL = `${this.$URLaddress}/${this.userPlatform}/frontoffice/API/index.php/Properties/allowed`;
    this.getAllowedProperties();
  },
  methods: {
    validateData() {
      this.getAnswers();
    },
    getAnswers() {
      this.$axios.get(`${this.feedbackQuestionsURL}/${this.usertoken}/${this.cname}/${this.answers.date}`)
        .then(res => {
          if(res.status === 200) this.allExistingAnswers = res.data.data;
        }
      )
    },
    getAllowedProperties() {
      this.$axios.get(`${this.allowedPropertiesURL}/${this.usertoken}/${this.cname}`)
        .then(res => {
          if(res.status === 200) this.allowedProperties = res.data.data.properties_allowed;
        }
      )
    }
  }
}
</script>